/* Container */
.phone-input-container {
  position: relative;
  width: 100%;
}
.phone-input-wrapper {
  display: flex;
  width: 100%;
  gap: 12px;
}
/* Country Selector */
.country-selector {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 1rem 1.25rem;
  background-color: white;
  cursor: pointer;
  border: 1px solid rgba($color: #d3d3d3, $alpha: 0.4);
  border-radius: 8px;
}

.country-selector:hover {
  background-color: #f7fafc;
}

.country-flag {
  width: 20x;
  height: 18px;
  margin-right: 0.2rem;
}
.country-dial-code {
  font-size: 1rem;
  font-family: inherit;
  margin-right: 12px;
}

/* Phone Input */
.phone-input {
  font-family: inherit;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  padding: 1rem 1.25rem;
  border: 1px solid rgba($color: #d3d3d3, $alpha: 0.4);
  border-radius: 8px;
  background: inherit;
  width: 100%;
}

/* Dropdown */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  &-search {
    padding: 0.5rem;
    border-bottom: 1px solid #e2e8f0;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: white;
    overflow: hidden;
    &-input {
      width: 100%;
      border: none;
      border-radius: 0.375rem;
      font-size: 0.875rem;
      outline: none;
    }

    .input--container {
      display: flex;
      border: 1px solid #e2e8f0;
      gap: 6px;
      padding: 6px;
      border-radius: 4px;
    }
  }
}

/* Country Option */
.country-option {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
}

.country-option:hover {
  background-color: #f7fafc;
}

/* Error Message */
.error-message {
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

/* Search Input */
.search-input {
  width: 100%;
  padding: 0.5rem;
  padding-left: 2.25rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  outline: none;
}

.search-input:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 1px #4299e1;
}
