.modal__content {
  .main-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 24px;
    color: $gray;
  }

  .confirmation {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 32px;
    color: $gray;
  }
}

.payment-success {
  color: $black;
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.5rem;
}

.payment-success_txt {
  color: $gray;
  font-size: 1rem;
  font-style: normal;
  line-height: 150%;

  span {
    color: #005397;
    font-weight: 500;
  }
}

.confirm-deets-modal {
  // position: relative;

  &__title {
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: $gray;

    span {
      text-transform: capitalize;
    }
  }

  &__price-banner {
    position: relative;
    min-width: 339px;
    width: fit-content;
    padding: 1.5rem 1.25rem;
    background-color: $primary-light-1;
    text-align: center;
    border-radius: 4px;

    @media #{ $smalldown } {
      min-width: unset;
      width: 100%;
    }

    .__top--value {
      position: relative;
      font-size: 2rem;
      font-weight: 600;
      line-height: 39px;
      margin-top: 0.625rem;

      @media #{ $smalldown } {
        font-size: 1.75rem;
        line-height: 30px;
      }

      .__period {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: $gray;
      }
    }

    .__top--worth {
      padding: 0.5rem 1rem;
      margin-top: 0.5rem;
      font-weight: 500;
      line-height: 20px;
      color: $f-blue;

      @media #{ $smalldown } {
        font-size: 0.875rem;
      }
    }
  }

  &__list {
    position: relative;
    margin-top: 2.5rem;

    &--deets {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      @media #{ $xsmalldown } {
        display: block;
      }

      p {
        font-weight: 500;
        line-height: 20px;
        text-transform: capitalize;

        @media #{ $xsmalldown } {
          margin-top: 0.875rem;
        }

        &:first-child {
          color: $gray;
          text-transform: capitalize;

          @media #{ $xsmalldown } {
            margin-top: 0;
          }
        }
      }
    }
  }

  .card-type {
    width: 32px;
    height: 24px;
    color: #fff3f3;
  }

  &__terms {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $gray;
    margin-top: 24px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    a {
      color: $gray;
      text-decoration: underline;
    }

    span {
      text-transform: uppercase;
    }
  }
}

.success-modal {
  position: relative;

  svg {
    display: block;
    margin: auto;
  }

  img {
    display: block;
    margin: auto;
  }

  h3 {
    position: relative;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin-top: 2rem;
  }

  .success__subtext {
    position: relative;
    text-align: center;
    color: $gray;
    margin-top: 1rem;
    line-height: 24px;
  }

  .btn {
    margin-top: 1.8rem;
  }
}

/* Modal overlay with opacity transition */
.modal-overlay-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 11, 20, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  /* Start invisible */
  visibility: hidden;
  /* Hide the modal until it's active */
  transition: opacity 0.3s ease, visibility 0.3s ease;
  /* Fade in transition */
}

/* Modal overlay active (visible) */
.modal-overlay-bg.active {
  opacity: 1;
  visibility: visible;
}

/* The modal content with slide-in effect */
.modal-content-bg {
  background: white;

  border-radius: 8px;
  max-width: 80vw;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  position: relative;
  transform: translateY(-50px);
  /* Start slightly offscreen (above) */
  transition: transform 0.3s ease;



  /* Slide-in transition */
  @media #{ $mediumdown } {
    max-width: 98vw;
    width: 98%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #edeff5;
    padding: 1rem 2.5rem;

    @media #{ $mediumdown } {
      padding: 1rem 1rem;
    }



    >p {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 24px;

      @media screen and (max-width: 575.98px) {
        font-size: 1rem;
        line-height: 24px;
      }
    }

    .closebtn {
      >svg {
        cursor: pointer;
        color: #fff;
      }
    }
  }

  &__body {
    padding: 1.5rem 2.5rem;

    @media #{ $mediumdown } {
      padding: 1.5rem 1rem;
    }


  }
}

.modal-content-bg--variant {
  background: white;
  border: 1px solid #F5F5F5;
  border-radius: 8px;
  max-width: 80vw;

  overflow-y: auto;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  position: relative;
  transform: translateY(-50px);
  /* Start slightly offscreen (above) */
  transition: transform 0.3s ease;



  /* Slide-in transition */
  @media #{ $mediumdown } {
    max-width: 98vw;
    width: 98%;
  }

  /* Slide-in transition */
  @media #{ $smalldown } {
    width: 100%;
    border-radius: 0;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #edeff5;
    padding: 1rem 1.875rem;

    @media #{ $mediumdown } {
      padding: 1.5rem;
    }

    >p {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 24px;

      @media screen and (max-width: 575.98px) {
        font-size: 1rem;
        line-height: 24px;
      }
    }

    .closebtn {
      >svg {
        cursor: pointer;
      }
    }
  }

  &__body {
    padding: 1.875rem;
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.875rem;

    @media #{ $mediumdown } {
      padding: 1.5rem;
      width: 100%;
    }

    @media #{ $smalldown } {
      height: calc(100% - 186px);
      justify-content: space-between;
    }


    .notification__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1.875rem;
      width: 100%;

      

      .text_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        @media #{ $smalldown } {
          gap: 6px
          
        }

        .notification__title {
          font-size: 24px;
          font-weight: 500;
          line-height: 29.0px;
          color: #000B14;
          text-align: center;

          @media #{ $smalldown } {
            font-size: 14px;
            line-height: 16.9px;
          }
        }

        .notification__text {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.36px;
          color: #5C6368;
          text-align: center;
          @media #{ $smalldown } {
            font-size: 12px;
            line-height: 14.52px;
          }
        }
      }

      .new_client_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 100%;
        background-color: #EAFFEE;
        border: 2px dashed #4EB765;
        border-radius: 12px;
        padding: 1.875rem;

        .new_client_name {
          font-size: 32px;
          font-weight: 600;
          line-height: 38.73px;
          color: #000B14;
          text-align: center;
          text-transform: capitalize;
        }

        .sub_text {
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          color: #236F00;
          text-align: center;
        }
      }

 


    }
 
  }
  &__footer {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    padding-top: 0;

    @media #{ $smalldown } {
      border-top: 1px solid #edeff5;
      padding: 16px 20px;

    }
    
  }

}

/* Modal content active (visible) */
.modal-overlay-bg.active .modal-content-bg, .modal-overlay-bg.active .modal-content-bg--variant {
  transform: translateY(0);
  /* Slide into position */
}

.warningModal {
  span {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29.05px;
    text-align: center;
    text-decoration-skip-ink: none;
    margin-top: 30px;
  }

  .__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #5c6368;
    margin-top: 24px;
  }

  .__text-btm {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #000b14;
  }
}

.warning__list {
  width: 100%;
  margin-top: 24px;

  &--header {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 8px 0px;
    background-color: #ffdde5;
    overflow: hidden;

    >p {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #e0063c;
    }
  }

  &--body {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 8px 20px;
    overflow: scroll;
    width: 100%;
    height: 91px;
    border: 1px solid #d3d3d399;
    border-top: none;

    ul {
      >li {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        padding-bottom: 6px;
        text-transform: capitalize;
        color: #5c6368;
      }
    }
  }
}