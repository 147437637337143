.settings {
  padding: 40px;

  @media #{ $mediumdown } {
    padding: 20px;
  }

  &__header {
    > ul {
      display: flex;
      gap: 12px;
      overflow-x: auto;

      @media #{ $xs-md-down } {
        justify-content: space-between;
        border: 2px solid #fff3f3;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      li {
        padding: 12px 20px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #5c6368;
        cursor: pointer;
        transition: all 0.5ms;

        &:hover {
          color: #000b14;
        }

        @media #{ $xs-md-down } {
          padding: 12px 16px;
          font-size: 12px;
        }
      }

      .active {
        background-color: #fff3f3;
        border-bottom: 2px solid #ff8788;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        transition: all 0.5s;
        color: #000b14;
      }
    }
  }
}

.account {
  padding-top: 20px;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d3d3d399;
    border-radius: 12px;
    padding: 30px;

    @media #{ $mediumdown } {
      flex-direction: column;
      align-items: flex-start;
    }

    &--img {
      display: flex;
      align-items: center;
      gap: 15px;

      .img__container {
        width: 100px;
        height: 100px;
        background: #000;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media #{ $mediumdown } {
          width: 50px;
          height: 50px;
        }
      }

      .img__text {
        > h4 {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          text-align: left;
          color: #000b14;

          @media #{ $mediumdown } {
            font-size: 16px;
            font-weight: 600;
            line-height: 19.36px;
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #5c6368;

          @media #{ $mediumdown } {
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
          }
        }
      }
    }

    &--btn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      @media #{ $mediumdown } {
        margin-top: 20px;
        width: 100%;
        align-items: center;
      }

      .change {
        width: 152px;

        @media #{ $mediumdown } {
          width: 100%;
        }
      }

      > p {
        color: #5c6368;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
        text-align: left;
        margin-top: 12px;

        @media #{ $mediumdown } {
          text-align: center;
        }
      }
    }
  }

  &__body {
    margin-top: 40px;
    border: 1px solid #d3d3d399;
    border-radius: 12px;
    padding: 30px;

    &--container {
      margin-top: 20px;
      border: 1px solid #d3d3d399;
      padding: 20px 30px;
      border-radius: 12px;
      display: flex;
      gap: 12px;
      align-items: center;
      width: 100%;

      @media #{ $smalldown } {
        border: none;
        padding: 0px;
      }

      .duration-toggle {
        width: 100%;
        .content {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 12px;
          margin-top: 10px;
          padding: 4px 0;

          @media #{ $smalldown } {
            flex-direction: column;
            align-items: flex-start;
            border: 1px solid #f5f5f5;
            border-radius: 8px;
            padding: 12px;
          }

          .toggle--container {
            color: #344054;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
          }

          .options--container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            gap: 20px;

            .option {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap: 16px;
              padding: 12px;
              border: 1px solid #f5f5f5;
              border-radius: 8px;

              input {
                width: 16px;
                height: 16px;
              }

              .item {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;

                span {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 100%;
                  letter-spacing: 0%;
                  color: #5c6368;
                }
              }
            }
          }
        }
      }

      .rightText {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        color: #000b14;

        > span {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          color: #e68f00;
          background-color: #fffaf3;
          padding: 4px 8px;
          border-radius: 4px;
          margin-right: 8px;

          @media #{ $smalldown } {
            display: block;
            margin-top: 6px;
            margin-bottom: 6px;
          }
        }
      }

      .rightText--btm {
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        color: #5c6368;
        margin-top: 4px;
      }
    }

    &--bg {
      @media #{ $smalldown } {
        display: none;
      }
    }

    &--sm {
      display: block;

      @media #{ $smallup } {
        display: none;
      }

      .sm--container {
        gap: 12px;
        align-items: center;
        display: flex;
      }
    }
  }

  @media #{ $smalldown } {
    border: none;
    padding: 0px;
  }
}

.keyperson {
  padding-top: 20px;

  &__header {
    h3 {
      color: #000b14;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;

      @media #{ $xs-md-down } {
        font-size: 16px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: #5c6368;
      padding-top: 6px;

      @media #{ $xs-md-down } {
        padding-top: 0px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  &__update {
    display: flex;
    justify-content: flex-end;

    .update {
      width: 130px;

      @media #{ $xs-md-down } {
        display: none;
      }
    }

    &--sm {
      margin-top: 24px;

      @media #{ $xs-md-up } {
        display: none;
      }
    }
  }

  &__body {
    border: 1px solid #d3d3d399;
    border-radius: 12px;
    padding: 30px;
    margin-top: 20px;

    @media #{ $xs-md-down } {
      border: none;
      padding: 0px;
    }
  }
}

.security {
  padding-top: 20px;

  &__header {
    h3 {
      color: #000b14;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      text-align: left;

      @media #{ $xs-md-down } {
        font-size: 16px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      color: #5c6368;
      padding-top: 6px;

      @media #{ $xs-md-down } {
        padding-top: 0px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  &__body {
    display: flex;
    gap: 48px;
    margin-top: 20px;

    @media #{ $mediumdown } {
      flex-direction: column-reverse;
      gap: 30px;
    }

    @media #{ $xs-md-down } {
      gap: 0px;
    }

    &--left {
      width: 35%;
      border-radius: 12px;
      background-color: #f5f5f5;
      padding: 48px 30px;
      color: #5c6368;

      @media #{ $mediumdown } {
        width: 100%;
      }

      @media #{ $xs-md-down } {
        padding: 16px 20px;
      }

      > h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        text-align: left;

        @media #{ $xs-md-down } {
          font-size: 14px;
          line-height: 16px;
        }
      }

      ul {
        margin-top: 12px;
      }

      li {
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
        margin-bottom: 12px;

        @media #{ $xs-md-down } {
          font-size: 12px;
          line-height: 14px;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    &--right {
      width: 65%;
      border-radius: 12px;
      border: 1px solid #d3d3d399;
      padding: 30px;

      @media #{ $mediumdown } {
        width: 100%;
      }

      @media #{ $xs-md-down } {
        border: none;
        padding: 0px;
      }

      .btns {
        display: flex;
        justify-content: flex-end;
        gap: 20px;

        @media #{ $xs-md-down } {
          display: none;
        }
      }
    }
  }
}

.rolemanagement {
  padding-top: 20px;

  &__header {
    display: flex;
    justify-content: space-between;

    &__toggle {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 408px;
      height: 56px;
      border-radius: 40px;
      background-color: #f5f5f5;
      gap: 4px;
      padding: 4px 5px;
      align-items: center;
      justify-content: space-between;

      &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        width: 50%;
        height: 100%;
        color: #979797;

        span {
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          color: #5c6368;
          letter-spacing: 0.02em;
        }
      }

      &__item--active {
        background-color: #005397;
        border-radius: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 50%;
        height: 100%;
        cursor: pointer;
        color: #ffffff;

        span {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          text-align: left;
          letter-spacing: 0.02em;
        }
      }
    }
  }

  &__body {
    margin-top: 20px;

    &__members {
      border: 1px solid #f2f6f5;
      border-radius: 15px;

      @media #{ $mediumdown } {
        border: none;
      }
    }

    &__roles {
      border: 1px solid #f2f6f5;
      border-radius: 15px;
      width: 100%;

      &__header {
        width: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid #f2f6f5;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background: #f5f5f5;

        h4 {
          font-size: 14px;
          font-weight: 600;
          text-align: left;
          color: #000b14;
          letter-spacing: 0.02em;
          line-height: 150%;
          text-transform: uppercase;
        }
      }

      &__body {
        width: 100%;

        &__row {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          padding: 20px 20px;
          border-top: 1px solid #f2f6f5;

          &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 8px;
            cursor: not-allowed;

            span {
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              color: #5c6368;
              line-height: 150%;
            }
          }
        }
      }
    }

    .memberName {
      gap: 8px;
      padding: 8px 0px;
      white-space: nowrap;

      &__icon {
        display: inline-block !important;
        margin-right: 8px;
        margin-left: 20px;
        width: 32px;
        height: 32px;
        background-color: #fff3f3;
        border: 1px solid #ff8788;
        border-radius: 50%;
        padding: auto;
        text-align: center;
        line-height: 32px;
        color: #3d0814;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
      }

      &__name {
        display: inline-block;
      }
    }
  }
}
