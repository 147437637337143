.search {
  &__header {
    display: flex;
    justify-content: space-between;
    @media #{ $mediumdown } {
      flex-direction: column-reverse;
      gap: 16px;
    }
    &--filterContainer {
      position: relative;
    }
    &--container {
      display: flex;
      gap: 1.5rem;
      .filterList {
        position: absolute;
        height: fit-content;
        width: 220px;
        background-color: #fffcfc;
        // padding-top: 1.5rem;
        z-index: 1;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
        border-radius: 10px;

        li {
          // margin-bottom: 1.5rem;
          cursor: pointer;
          padding: 0.7rem 0 0.7rem 1.5rem;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.004em;
          text-align: left;
          color: #5c6368;
          &:hover {
            background: $off-white;
          }
          text-transform: capitalize;
        }
      }
    }
    &--filter {
      display: flex;
      align-items: center;
      width: 102px;
      height: 40px;
      justify-content: space-between;
      border: 1px solid #f5f5f5;
      padding: 0.875rem;
      border-radius: 6px;
      cursor: pointer;
      > p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: $black;
        opacity: 0.8;
        text-transform: capitalize;
      }
    }
    &--search {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 404px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #d3d3d3;
      border-radius: $preferredradius;
      padding: 12px;
      opacity: 0.8;
      // svg {
      // 	opacity: 0.4;
      // }
      @media #{ $xs-md-down } {
        width: 100%;
      }
      input {
        outline: none;
        border: none;
        // &::placeholder {
        // 	opacity: 0.4;
        // }
        //
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        color: $black;
        width: 95%;
      }
    }
    &--btn {
      width: 176px;
      height: 40px;
    }
  }
}
