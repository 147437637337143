.transactions {
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  padding: 40px;

  @media #{ $mediumdown } {
    padding: 20px;
    max-width: 100%;
  }

  &__header {
    margin-top: 30px;
    border: 1px solid #d3d3d399;
    background-color: #fff;
    padding: 16px;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;

    @media #{ $mediumdown } {
      flex-direction: column;
      gap: 20px;
    }

    &--left {
      display: flex;
      gap: 1rem;

      .balance {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.02em;
          color: #5c6368;
          text-transform: uppercase;

          @media #{ $mediumdown } {
            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
          }
        }

        h4 {
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0.02em;
          color: #000b14;

          @media #{ $mediumdown } {
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: 0.02em;
          }
        }
      }
    }

    &--right {
      .nairaIcon {
        margin-right: 6px;
      }

      @media #{ $mediumdown } {
        width: 100%;

        .btn {
          width: 100%;
        }
      }
    }
  }

  &__body {
    margin-top: 30px;

    &__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29.05px;
      }

      &--filter {
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: start;
        min-width: 153px;
        height: 44px;
        border: 1px solid #F2F6F5;
        border-radius: 8px;
        padding: 12px;
        gap: 12px;
        position: relative;
        cursor: pointer;





        svg: {
          color: #5C6368;
        }

        .filter__dropdown--container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 6px;


          .filter__dropdown {
            display: flex;
            align-items: center;
            gap: 5px;

            >p {
              font-size: 16px;
              font-weight: 400;
              line-height: 19.36px;
              min-width: 75px;
              color: #5C6368;
              text-wrap: nowrap;
            }

            &--list {
              position: absolute;
              z-index: 1;
              right: 0;
              top: 102%;
              padding: 0.5rem 0;
              min-width: 180px;
              background-color: #fff;
              border-radius: 10px;
              box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
              box-sizing: border-box;

              ul {
                margin: 0rem !important;
              }

              li {
                width: 100%;
                padding: 0.6rem 1rem;
                cursor: pointer;

                font-size: 14px;
                font-weight: 400;
                line-height: 14.52px;
                text-align: left;
                color: #5c6368;

                &:hover {
                  background: $off-white;
                }
              }
            }
          }

        }

        .custom__date--container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 8px;

          width: 100%;

          .date-picker-inputs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 8px;

            span {
              font-size: 14px;
              font-weight: 400;
              color: #5C6368;
            }
          }


          .react-date-picker {
            display: inline-flex;
            position: relative;
          }

          .react-date-picker,
          .react-date-picker *,
          .react-date-picker *:before,
          .react-date-picker *:after {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          .react-date-picker--disabled {
            color: #6d6d6d;
          }

          .react-date-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            padding: 0;

          }

          .react-date-picker__inputGroup {
            width: fit-content;
            flex-grow: 1;
            padding: 0 2px;
            box-sizing: content-box;
            padding: 0;

            &:focus-within {

              .react-date-picker__inputGroup__divider,
              .react-date-picker__inputGroup__input {
                color: #000000;
              }
            }
          }

          .react-date-picker__inputGroup__divider {
            padding: 1px 0;
            white-space: pre;
            color: #5C6368;
          }

          .react-date-picker__inputGroup__divider,
          .react-date-picker__inputGroup__leadingZero {
            display: inline-block;
            font: inherit;
          }

          .react-date-picker__inputGroup__input {
            min-width: 0.54em;
            height: 100%;
            position: relative;
            padding: 0 1px;
            border: 0;
            background: none;
            color: currentColor;
            font: inherit;
            box-sizing: content-box;
            -webkit-appearance: textfield;
            -moz-appearance: textfield;
            appearance: textfield;

          }

          .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
          .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            margin: 0;
          }



          .react-date-picker__inputGroup__input--hasLeadingZero {
            margin-left: -0.54em;
            padding-left: calc(1px + 0.54em);
          }

          .react-date-picker__button {
            display: none;
          }


          .react-date-picker__button svg {
            display: none;
          }

          .react-date-picker__calendar {
            top: 30px !important;
            width: 350px;
            max-width: 100vw;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: end;
            justify-content: end;
          }

          .react-date-picker__calendar--closed {
            display: none;
          }

          .react-date-picker__calendar .react-calendar {
            border-width: thin;
          }









          .react-calendar {
            width: 284px;
            max-width: 100%;
            background: white;
            border: 1px solid #F3F3F3;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 12px rgba(0, 0, 0, 0.08);
            padding: 16px;
            border-radius: 8px;
            line-height: 1.125em;
          }


          .react-calendar--doubleView .react-calendar__viewContainer {
            display: flex;
            margin: -0.5em;
          }


          .react-calendar,
          .react-calendar *,
          .react-calendar *:before,
          .react-calendar *:after {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          .react-calendar button {
            margin: 0;
            border: 0;
            outline: none;
          }

          .react-calendar button:enabled:hover {
            cursor: pointer;
          }

          .react-calendar__navigation {
            display: flex;
            height: 44px;
            margin-bottom: 1em;
            span{
              font-size: 15px;
              font-weight: 500;
              line-height: 140%;
              color: #000B14;
            }
          }

          .react-calendar__navigation button {
            min-width: 44px;
            background: none;
          }


          .react-calendar__navigation button:enabled:hover,
          .react-calendar__navigation button:enabled:focus {
            background-color: #e6e6e6;
          }

          .react-calendar__month-view__weekdays {
            text-align: center;
            text-transform: capitalize;

            font: inherit;
            font-size: 15px;
            font-weight: 400;

            abbr {
              text-decoration: none;
              line-height: 150%;
              color: #5C6368;
            }
          }

          .react-calendar__month-view__weekdays__weekday {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
          }


          .react-calendar__month-view__days__day--neighboringMonth,
          .react-calendar__decade-view__years__year--neighboringDecade,
          .react-calendar__century-view__decades__decade--neighboringCentury {
            color: #5C6368 !important;
            
          }


          .react-calendar__tile {
            max-width: 100%;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3px 9px;
            background: none;
            text-align: center;
            font: inherit;
            font-size: 15px;
            font-weight: 500;
            line-height: 140%;
            border-radius: 8px;
            color: #000B14;
          }

          .react-calendar__tile:disabled {
            background-color: #f0f0f0;
            color: #ababab;
          }

          .react-calendar__month-view__days__day--neighboringMonth:disabled,
          .react-calendar__decade-view__years__year--neighboringDecade:disabled,
          .react-calendar__century-view__decades__decade--neighboringCentury:disabled {
            color: #5C6368 !important;
          }

          .react-calendar__tile:enabled:hover,
          .react-calendar__tile:enabled:focus {
            background-color: #e6e6e6;
          }



          .react-calendar__tile--hasActive {
            background: #EDF0F2;
          }

          .react-calendar__tile--hasActive:enabled:hover,
          .react-calendar__tile--hasActive:enabled:focus {
            background: #a9d4ff;
          }

          .react-calendar__tile--active {
            background: #EDF0F2;

          }

          .react-calendar__tile--active:enabled:hover,
          .react-calendar__tile--active:enabled:focus {
            background: #1087ff;
          }

          .react-calendar--selectRange .react-calendar__tile--hover {
            background-color: #e6e6e6;
          }


        }
      }


    }


  }
}

.fundTransactions {
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #000b14;
  }

  &__table {
    padding: 20px;
    background-color: #f5f5f5;
    width: 100%;
    border: 1px solid #d3d3d399;
    border-radius: 12px;
    margin-top: 12px;

    .table--data {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #d3d3d399;
      padding: 8px 0px;

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #5c6368;
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0px;
      }

      &:first-child {
        padding-top: 0px;
      }

      .name {
        p {
          color: #005397;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .copyBtn {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #000b14;
        cursor: pointer;

        span {
          margin-right: 10px;
        }
      }

      .bank {
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #000b14;
        }
      }

      .status {
        padding: 4px 8px;
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
      }

      .success {
        background-color: #f3fff8;
        padding: 4px 8px;
        color: #269700;
        border-radius: 40px;
      }
    }
  }

  .hint {
    display: flex;
    width: 100%;
    gap: 8px;
    background-color: #f5fbff;
    padding: 16px;
    border-radius: 8px;
    margin-top: 12px;

    @media #{ $smalldown } {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
    }

    >svg {
      color: #005397;
    }

    >p {
      padding: 0px;
      font-weight: 500;
      color: $f-blue;

      a {
        font-weight: 600;
        cursor: pointer;
        color: $f-blue;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .btn {
    margin-top: 10px;
    display: flex;
    padding: 8px 0px;
    gap: 30px;
  }
}