//Header
//author: @Anisat Yunusa

.dashboard {
  display: flex;
  width: 100%;

  &__main {
    width: 84%;
    overflow-x: hidden;
    overflow-y: scroll;

    @media #{ $largedown } {
      width: 100%;
    }

    .container {
      margin-top: 80px;
      width: 100%;
      @media #{ $largedown } {
        margin-bottom: 104px;
      }



      .dashboard__notification__banner {
        margin: 40px;
        margin-bottom: 0px;

        @media #{ $mediumdown } {
          margin: 20px;
          margin-bottom: 0px;
        }

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        justify-content: space-between;
        padding: 16px 12px;
        padding-left: 24px;
        border-radius: 4px;
        border-left: 8px solid #E98C00;
        background-color: #FFF5E6;
        flex-wrap: nowrap;
        position: relative;

        @media #{ $smalldown } {
          align-items: start;
        }



        &__icon {
          width: 24px;
          height: 24px;

          @media #{ $smalldown } {
            position: absolute;
            top: 16px;
            left: 24px;


          }
        }

        &__text__container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 8px;

          @media #{ $smalldown } {
            margin-left: 40px;
            flex-direction: column;
            align-items: start;
          }
        }

        &__text {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #000B14;
          text-align: start;
          text-wrap: wrap;


        }

        &__text__close {
          margin-left: auto;
          font-weight: 600;
          font-size: 18px;
          line-height: 21.76px;
          color: #E98C00;
          text-align: start;
          cursor: pointer;

          @media #{ $smalldown } {
            margin-left: 0;
          }

        }



      }
    }


    .floating__navigation {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 104px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: start;
      padding: 0 40px;
      z-index: 100;

      @media #{ $largescreenup } {
        display: none;
      }

      @media #{ $mediumdown } {
        padding: 0 20px;
      }

      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        border-radius: 8px;
        background-color: #005397;
        padding: 12px;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          height: 46px;
          padding: 8px 6px;
          width: 100%;
          border-radius: 8px;
          background-color: #005397;
          cursor: pointer;

          p {

            font-weight: 500;
            font-size: 10px;
            line-height: 100%;
            letter-spacing: 0%;
            text-align: center;
            color: #fff;
            text-transform: capitalize;

          }

          &--active {
            background-color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            height: 46px;
            padding: 8px 6px;
            width: 100%;
            border-radius: 8px;
            cursor: pointer;

            p {

              font-weight: 500;
              font-size: 10px;
              line-height: 100%;
              letter-spacing: 0%;
              text-align: center;
              color: #005397;
              text-transform: capitalize;
  
            }

          }


        }

      }
    }
  }
}