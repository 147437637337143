.clients {
  padding: 40px;
  @media #{ $mediumdown } {
    padding: 20px;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    > .btns {
      display: flex;
      gap: 20px;
      max-width: 500px;
      @media #{ $mediumdown } {
        max-width: 100%;
        flex-direction: column;
        width: 100%;
      }
      span {
        margin-right: 8px;
        font-size: 24px;
      }
      //   justify-content: end;
    }
  }

  &--info {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media #{ $mediumdown } {
        flex-direction: column;
        align-items: flex-start;
      }

      .penIcon {
        margin-right: 8px;
      }
      .act-btns {
        display: flex;
        gap: 20px;
      }
    }
    &__body {
      margin-top: 40px;
      .deets {
        border: 1px solid #d3d3d399;
        overflow: hidden;
        border-radius: 12px;
        margin-bottom: 26px;

        &--header {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #344054;
          background-color: #f5f5f5;
          padding: 20px 30px;
        }
        &--body {
          padding: 25px 30px;
          display: flex;
          gap: 22px;
          width: 100%;
          @media #{ $mediumdown } {
            flex-direction: column;
          }
          .client--image {
            border: 1px solid #e4e7ec;
            padding: 18.5px 24px;
            border-radius: 8px;
            display: flex;
            width: 35%;
            align-items: center;
            justify-content: space-between;
            @media #{ $mediumdown } {
              width: 100%;
              border: none;
            }
            .img--container {
              width: 131px;
              height: 131px;
              border-radius: 50%;
              overflow: hidden;
              @media #{ $mediumdown } {
                width: 100px;
                height: 100px;
              }

              span {
                width: 100%;
                height: 100%;
                background-color: #fff3f3;
                display: flex;
                align-items: center;
                justify-content: center;
                // display: block;
                p {
                  font-size: 48px;
                  font-weight: 500;
                  line-height: 58.09px;
                  color: #3d0814;
                }
              }
            }
            .upload__btn {
              position: relative;
              .upload {
                display: flex;
                gap: 6px;
                color: $f-blue;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;

                background-color: #f5f5f5;
                padding: 12px 20px;
                cursor: pointer;
                border-radius: 40px;
                align-items: center;
              }
              @media #{ $smalldown } {
                font-size: 12px;
                font-weight: 600;
                line-height: 16.94px;
                text-align: left;
              }
              > input {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
                box-sizing: border-box;
              }
            }
          }
          .empty-state {
            width: 100%;
          }
          .form {
            width: 65%;
            border: 1px solid #e4e7ec;
            padding: 18.5px 24px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media #{ $mediumdown } {
              width: 100%;
            }
            > .inputgroup {
              display: flex;

              .address--deets {
                width: 50%;
                @media #{ $smalldown } {
                  width: 100%;
                  margin-bottom: 10px;
                }
                p {
                  color: #5c6368;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 16.94px;
                  display: flex;
                  align-items: center;
                  margin-bottom: 6px;
                  text-transform: uppercase;
                  span {
                    margin-right: 5px;
                  }
                }
                h4 {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 19.36px;
                  color: $black;
                }
              }
            }
          }
          .input-group {
            width: 100%;
            .address--deets {
              width: 50%;
              @media #{ $smalldown } {
                width: 100%;
              }
              p {
                color: #5c6368;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                text-transform: uppercase;
                span {
                  margin-right: 5px;
                }
              }
              h4 {
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                color: $black;
                text-transform: capitalize;
              }
            }
          }
          .activity--body {
            width: 100%;
          }
          .activites__table {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border: 1px solid #f5f5f5;
            margin-bottom: 20px;
            padding: 20px;
            border-radius: 12px;
            &--left {
              h4 {
                font-size: 20px;
                font-weight: 500;
                line-height: 24.2px;
                color: #005397;
              }
              h5 {
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                color: #5c6368;
                padding-top: 6px;
              }
              p {
                padding-top: 12px;
                color: #5c6368;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
              }
            }
            &--right {
              p {
                color: #5c6368;
                font-size: 16px;
                font-weight: 500;
                line-height: 19.36px;
                text-align: right;
                padding-top: 6px;
              }
              .amount {
                padding-top: 6px;
                display: flex;
                align-items: center;
                gap: 12px;
                .status {
                  padding: 4px 8px;
                  display: block;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 16.94px;
                }
                .success {
                  background-color: #f3fff8;
                  padding: 4px 8px;
                  color: #269700;
                  border-radius: #269700;
                }
                p {
                  padding-bottom: 0px;
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 24px;
                  color: #005397;
                }
              }
            }
          }
        }
      }
    }
  }
  .table__header {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @media #{ $mediumdown } {
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }
    h4 {
      color: $black;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      text-align: left;
    }
  }
  .initials {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    text-align: center;
    color: #3d0814;
    padding: 8px;
    border-radius: 50%;
    background-color: #fff3f3;
    border: 1px solid #ff8788;
    margin-right: 8px;
  }
  .clientName {
    gap: 8px;
    padding: 8px 0px;
    white-space: nowrap;
    &__icon {
      display: inline-block !important;
      margin-right: 8px;
      width: 32px;
      height: 32px;
      background-color: #fff3f3;
      border: 1px solid #ff8788;
      border-radius: 50%;
      padding: auto;
      text-align: center;
      line-height: 32px;
      color: #3d0814;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
    }
    &__name {
      display: inline-block;
    }
  }
  .details {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 2%;
    text-align: center;
    vertical-align: middle;
    text-transform: uppercase;
    text-decoration: underline;
    color: #3d0814;
    text-wrap: nowrap;
    padding-right: 20px;
  }
  .clientIcon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
  .flancer {
    background-color: #fff3f3;
    > svg {
      fill: #ff8788;
      color: #ff8788;
    }
  }
  .non-flancer {
    background-color: #f5f5f5;

    > svg {
      fill: #5c6368;
      color: #5c6368;
    }
  }
}
.changeplan {
  .name {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.2px;

    color: #005397;
    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 24.2px;
      color: #5c6368;
    }
  }
  > form {
    margin-top: 30px;
    .input--group {
      display: flex;
      width: 100%;
      gap: 20px;
      margin-bottom: 30px;
      .address--deets {
        width: 33%;
      }
    }
  }
}
