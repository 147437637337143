.overview {
  padding: 40px;
  padding-top: 20px;

  @media #{ $mediumdown } {
    padding: 20px;
    padding-top: 10px;
  }

  &__utils {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media #{ $mediumdown } {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &--search {
      position: relative;

      @media #{ $mediumdown } {
        width: 100%;
        display: none;
      }

      .search--dropdown {
        overflow: scroll;
        position: absolute;
        top: 58px;
        width: 100%;
        background-color: #fff;
        height: 330px;
        box-shadow: rgba(149, 157, 165, 0.2) 24px 8px 24px 24px;
        border-radius: 8px;
        padding: 20px;

        .empty-search {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 100%;
          width: 100%;

          span {
            margin: 0 auto;
            width: fit-content;
            margin-bottom: 16px;
          }

          h4 {
            text-align: center;
            font-size: 18px;
            font-weight: 700;
            line-height: 23.44px;
            color: #344054;
            margin-bottom: 6px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 18.23px;
            text-align: center;

            color: #475467;
          }
        }

        .results {
          >p {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #5c6368;
            margin-bottom: 12px;
          }

          &__body {
            .listName {
              display: flex;
              gap: 12px;
              align-items: center;
              padding: 6px 6px;
              cursor: pointer;
              transform: all 0.5s;

              &:hover {
                background-color: #f5f5f5;
              }

              span {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                display: block;
                height: 40px;
                width: 40px;
                background: #fff3f3;
                border: 1px solid #ff8788;
                color: #5c6368;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                text-transform: capitalize;
              }

              p {
                font-size: 14px;
                font-weight: 500;
                line-height: 16.94px;
                text-align: left;
                color: #000b14;
              }
            }
          }
        }
      }
    }

    &--clients {
      display: flex;

      .search {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0 12px;
        background-color: #f3f3f3;
        border: 1px solid #dedede;
        width: 357px;
        height: 56px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        @media #{ $mediumdown } {
          width: 100%;
        }

        input {
          outline: none;
          border: none;
          background-color: transparent;
          width: 100%;
          height: 100%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.36px;
          letter-spacing: 0.02em;

          &::placeholder {
            font-size: 1rem;
            font-weight: 400;
            line-height: 19.36px;
            color: #667085cc;
          }
        }
      }
    }

    &--action {
      display: flex;
      gap: 20px;

      @media #{ $mediumdown } {
        width: 100%;
      }

      >.btn {
        @media #{ $mediumdown } {
          width: 100%;
        }
      }
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 18px;

    @media #{ $mediumdown } {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;
    }

    margin-bottom: 40px;

    @media #{ $mediumdown } {
      flex-direction: column;
    }
  }

  &__card {
    border: 1px solid #d3d3d399;
    padding: 16px 0px;
    border-radius: 12px;

    .__text {
      color: #5c6368;

      h3 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
      }

      >p {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;

        span {
          color: $black;
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0.02em;
        }
      }
    }

    &--activeClientsContainer{
      border: 1px solid #d3d3d399;
      border-radius: 12px;
      padding: 0;
      display: flex;
      flex-direction: column;


      @media #{ $mediumdown} {
        border: none;
        gap: 16px;

        hr{
          display: none;
        }
      }



    }

    &--activeClients {
      padding: 16px 20px;
      display: flex;
      gap: 12px;

      @media #{ $mediumdown} {
        border: 1px solid #d3d3d399;
        border-radius: 12px;
        padding: 16px;
      }

      >span {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5fbff;
        border-radius: 4px;
      }

      .__text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media #{ $smalldown} {
          p.mt-16 {
            background-color: red;
            margin-top: 0rem !important;
          }
        }
      }
    }

    &--balance {
      padding: 0px 20px;

      .balance {
        display: flex;
        gap: 30px;

        @media #{ $smalldown} {
          gap: 16px;
        }

        >span {
          width: 118px;
          height: 122px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 8px solid #029aff;
          border-radius: 50%;

          @media #{ $smalldown} {
            width: 70px;
            height: 70px;

            >svg {
              width: 24px !important;
              height: 24px !important;
            }
          }
        }
      }

      p {
        padding-top: 12px;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        letter-spacing: 0.02em;
        text-align: left;
        text-decoration-line: underline;
        color: #005397;
        cursor: pointer;

        @media #{ $smalldown} {
          padding-top: 10px;
        }
      }
    }

    &--members {
      padding: 0px 20px;
      display: flex;
      justify-content: space-between;

      @media #{ $smalldown} {
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
      }

      .members {
        >span {
          width: 118px;
          height: 122px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 8px solid #029aff;
          border-radius: 50%;

          @media #{ $smalldown} {
            width: 70px;
            height: 70px;

            >svg {
              width: 24px !important;
              height: 24px !important;
            }
          }
        }

        >p {
          padding-top: 12px;
          font-size: 14px;
          font-weight: 600;
          line-height: 16.94px;
          letter-spacing: 0.02em;
          text-align: left;
          text-decoration-line: underline;
          color: #005397;
          cursor: pointer;

          @media #{ $smalldown} {
            display: none;
          }
        }
      }

      .__text {
        p {
          width: 100%;
          display: flex;
          margin-bottom: 12px;

          // background-color: red;
        }

        p>span {
          width: fit-content;
          display: block;
          // background-color: #5c6368;
        }

        .status {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          padding: 4px 8px;
          border-radius: 4px;
          display: block;
          text-wrap: nowrap;
          letter-spacing: 0px;
          width: 84px;
          text-transform: none;
          margin-right: 8px;
        }

        .today {
          color: #e0063c;
          background-color: #fff3f3;
        }

        .week {
          color: #e68f00;
          background-color: #fffaf3;
        }

        .expired {
          color: #5f6062;
          background-color: #f5f5f5;
        }
      }
    }

    hr {
      border: none;
      border-bottom: 1px solid #d3d3d399;
    }

    .mb-members {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      letter-spacing: 0.02em;
      text-align: left;
      text-decoration-line: underline;
      color: #005397;
      cursor: pointer;
      padding-left: 20px;

      @media #{ $smallup } {
        display: none;
      }
    }

    &--visits {
      padding: 0px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
    
    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      align-items: start;
      h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0.005em;
        color: #0c0d0f;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        color: #5c6368;
      }
    }

    .body {
      display: flex;
      justify-content: center;

      .emptyState {
        display: flex;
        flex-direction: column;
        align-items: center;

        >P {
          color: #5c6368;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.3199999928474426px;
          text-align: center;
          margin-top: 20px;
        }
      }

      >span {
        width: 119px;
        height: 119px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 8px solid #005397;
        border-radius: 50%;

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          text-align: center;
          color: #5c6368;
        }

        h3 {
          font-size: 32px;
          font-weight: 600;
          line-height: 38.73px;
          text-align: center;
          color: #000b14;
        }
      }
    }
  }
  }

  &__bar {
    display: flex;
    gap: 20px;

    @media #{ $mediumdown} {
      flex-direction: column;
    }

    &--left {
      border: 1px solid #dcdee399;
      padding: 20px;
      background-color: #fff;
      border-radius: 1rem;
      width: 70%;

      @media #{ $mediumdown} {
        width: 100%;
      }

      .header {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        &--left {
          h3 {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0.005em;
            color: #000b14;
          }

          p {
            font-size: 24px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.005em;
            color: #5c6368;
          }
        }

        &--right {
          .search__header {
            &--filter {
              width: 150px;
            }
          }
        }
      }

      .barchart {
        height: 243px;
        width: 100%;
      }
    }

    &--right {
      padding: 20px;
      border: 1px solid #dcdee399;
      padding: 20px;
      background-color: #fff;
      border-radius: 1rem;
      width: 30%;

      @media #{ $mediumdown} {
        width: 100%;
      }

      .header {
        h3 {
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0.005em;
          color: #0c0d0f;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          color: #5c6368;
        }
      }

      .body {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 24px;

        .emptyState {
          display: flex;
          flex-direction: column;
          align-items: center;

          >P {
            color: #5c6368;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.3199999928474426px;
            text-align: center;
            margin-top: 20px;
          }
        }

        >span {
          width: 221px;
          height: 221px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 8px solid #005397;
          border-radius: 50%;

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
            text-align: center;
            color: #5c6368;
          }

          h3 {
            font-size: 32px;
            font-weight: 600;
            line-height: 38.73px;
            text-align: center;
            color: #000b14;
          }
        }
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 21.78px;
      text-align: left;
    }

    &--filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;
      width: 153px;
      height: 44px;
      border: 1px solid #F2F6F5;
      border-radius: 8px;
      padding: 16px;
      position: relative;
      cursor: pointer;

      svg: {
        color: #5C6368;
      }

      .filter__dropdown {
        display: flex;
        align-items: center;
        gap: 5px;

        >p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.36px;
          width: 75px;
          color: #5C6368;
          text-wrap: nowrap;
        }
        &--list {
          position: absolute;
          z-index: 1;
          right: 0;
          top: 105%;
          padding: 0.5rem 0;
          min-width: 180px;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
          box-sizing: border-box;
          ul {
            margin: 0rem !important;
          }
          li {
            width: 100%;
            padding: 0.6rem 1rem;
            cursor: pointer;
      
            font-size: 14px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
            color: #5c6368;
            &:hover {
              background: $off-white;
            }
          }
        }
      }
    }
  }

  @media #{ $xsmalldown } {
    padding: 20px;
  }
}