.member {
  max-width: 90%;
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  @media #{ $mediumdown } {
    padding: 20px;
    max-width: 100%;
  }
  &__header {
    &--title {
      h2 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29.05px;
        color: $black;
        @media #{ $mediumdown } {
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          text-align: left;
        }
      }
    }
    &--filter {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }
  }
  &__body {
    margin-top: 30px;
  }
}

.memberRenew--modal {
  .iconHead {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
  .note {
    display: flex;
    gap: 12px;
    align-items: center;
    background-color: #f5fbff;
    padding: 16px;
    p {
      color: #005397;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    svg {
      color: #005397;
    }
  }
  .btn {
    display: flex;
    width: 100%;
    gap: 16px;
    margin-top: 16px;
  }
}

.member__footer {
  height: 80px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #e7f5ff;
  &--container {
    max-width: 90%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{ $mediumdown } {
      max-width: 100%;
      padding: 0 1rem;
    }
  }
  .__left {
    display: flex;
    gap: 1rem;
    @media #{ $mediumdown } {
      display: none;
    }

    .selected {
      display: flex;
      gap: 6px;
      align-items: center;
      font-family: Inter;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      color: #5c6368;
      > span {
        background-color: #005397;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 10px;
        font-weight: 500;
        line-height: 12.1px;
        color: #fff;
      }
    }
    .deselect {
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      color: #5c6368;
      background: #f5f5f5;
      padding: 12px 24px;
      border-radius: 100px;
      cursor: pointer;
    }
  }
  .__right {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.36px;
    color: #000b14;
    font-family: Inter;
    @media #{ $mediumdown } {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
    }

    span {
      background-color: #005397;
      color: #fff;
      padding: 12px 24px;
      margin-left: 12px;
      border-radius: 40px;
      cursor: pointer;
      @media #{ $mediumdown } {
        padding: 6px 12px;
      }
    }
  }
}

.mailContact {
  display: flex;
  gap: 10px;
}
