// //INPUTS
// //author: @Anisat Yunusa

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
// 	-webkit-appearance: none;
// 	margin: 0;
// }

// textarea {
// 	resize: none;
// }

// input[type='number'] {
// 	appearance: none;
// 	-moz-appearance: textfield;
// }

// .form-control {
// 	display: block;
// 	width: 100%;
// 	color: $black;
// 	font-family: 'Inter', sans-serif;
// 	&:focus {
// 		border-color: $primary;
// 		box-shadow: 0 0 0 0.2rem rgba($primary, 10%);
// 	}
// }

// .form-group {
// 	position: relative;
// 	margin-bottom: 1.5rem;
// 	// &:last-child {
// 	// 	background-color: red;
// 	// 	margin-bottom: 0rem !important;
// 	// }
// 	&:last-child {
// 		margin-bottom: 0rem;
// 	}

// 	#eye-icon {
// 		width: 16px;
// 		height: 15px;
// 	}

// 	#eye-icon-closed {
// 		width: 16px;
// 		height: 13px;
// 	}

// 	#toggle-icon,
// 	.input-icon {
// 		position: absolute;
// 		top: 20px;
// 		right: 20px;
// 		color: inherit;
// 		animation: fadeUp 0.3s linear forwards;
// 	}
// 	#toggle-icon {
// 		svg {
// 			cursor: pointer;
// 		}
// 	}

// 	@keyframes fadeUp {
// 		0% {
// 			transform: translateY(-8px);
// 			opacity: 0;
// 		}
// 		100% {
// 			opacity: 1;
// 			transform: translateY(0px);
// 		}
// 	}

// 	input,
// 	select,
// 	textarea {
// 		font-family: inherit;
// 		font-size: 1rem;
// 		appearance: none;
// 		-webkit-appearance: none;
// 		padding: 1rem 1.25rem;
// 		border: 1px solid rgba($color: #d3d3d3, $alpha: 0.4);
// 		border-radius: 8px;
// 		background: inherit;
// 		&:focus {
// 			outline: 0;
// 		}
// 		&:disabled {
// 			background: #f5f5f5;
// 			cursor: not-allowed;
// 		}
// 		@media screen and (max-width: 575.98px) {
// 			font-size: 0.875rem;
// 		}
// 	}

// 	// select {
// 	// 	background: url(../../assets/svgs/Down-arrow.svg) right 1.25rem center
// 	// 		no-repeat;

// 	// 	.select--placeholder {
// 	// 		color: rgba($color: $black, $alpha: 0.6) !important;
// 	// 	}
// 	// }

// 	.form-control--sm {
// 		height: 40px;
// 		padding: 10px 20px;
// 		font-size: 14px;
// 	}
// }

// .error {
// 	color: red;
// 	margin-top: 0.25rem;
// 	margin-bottom: 0;
// 	font-size: 0.75rem !important;
// 	&::first-letter {
// 		text-transform: uppercase;
// 	}
// }

// .helperText {
// 	position: relative;
// 	display: inline-block;
// 	color: $f-blue;
// 	font-weight: 500;
// 	margin-top: 0.75rem;
// }

// .floating-label {
// 	label {
// 		position: absolute;
// 		left: 1.25rem;
// 		color: rgba($color: #717171, $alpha: 0.6);
// 		font-size: 1rem;
// 		transform-origin: top left;
// 		text-transform: capitalize;
// 		transform: translate(0, 20px) scale(1); //move on the y-axis
// 		transition: 0.2s ease all;
// 		-moz-transition: 0.2s ease all;
// 		-webkit-transition: 0.2s ease all;
// 		pointer-events: none;
// 		-webkit-font-smoothing: antialiased;
// 		-moz-osx-font-smoothing: grayscale;
// 		-webkit-touch-callout: none;
// 		-webkit-user-select: none;
// 		-khtml-user-select: none;
// 		-moz-user-select: none;
// 		-ms-user-select: none;
// 		user-select: none;
// 		@media screen and (max-width: 575.98px) {
// 			font-size: 0.875rem;
// 		}
// 	}

// 	.form-control {
// 		height: 60px;
// 		&--sm {
// 			height: 40px;
// 			padding: 10px 20px !important;
// 			font-size: 14px;
// 		}
// 	}

// 	/** active label */
// 	&.active {
// 		label {
// 			color: $gray;
// 			//move the x coordinate and reduce size
// 			transform: translate(0, 10px) scale(0.75);
// 		}
// 	}
// 	&.active {
// 		input,
// 		select,
// 		textarea {
// 			padding: 25px 20px 7px 20px;
// 		}
// 	}
// }

// .form-control.textArea {
// 	height: 100px;
// }

// // input[type='date'],
// // input[type='time'] {
// //   	position: relative;
// // }

// // input[type='date']::-webkit-calendar-picker-indicator,
// // input[type='time']::-webkit-calendar-picker-indicator {
// // 	position: absolute;
// // 	left: 0;
// // 	top: 0;
// // 	width: 100%;
// // 	height: 100%;
// // 	margin: 0;
// // 	padding: 0;
// // 	cursor: pointer;
// // 	opacity: 0;
// // 	z-index: 9999;
// // }

// .inputgroup {
// 	display: flex;
// 	width: 100%;

// 	&:last-child {
// 		.form-group {
// 			margin-bottom: 0;
// 		}
// 	}
// 	.form-group,
// 	.btn {
// 		flex: 1;
// 		&:last-child {
// 			margin-left: 1.5rem;

// 			@media #{$mediumdown} {
// 				margin-left: 1rem;
// 			}
// 		}
// 	}
// 	@media #{$smalldown} {
// 		flex-direction: column;
// 		// @media #{ $largedown } {
// 		// 	margin-bottom: 1.5rem;
// 		// }

// 		&:last-child {
// 			.form-group,
// 			.btn {
// 				margin-bottom: 1.5rem;
// 				&:last-child {
// 					margin-bottom: 0;
// 				}
// 			}
// 		}
// 		.form-group,
// 		.btn,
// 		.card-check {
// 			&:last-child {
// 				margin-left: 0;
// 				margin-bottom: 1.5rem;
// 			}
// 		}

// 		.btn {
// 			flex: unset;
// 		}
// 	}
// }

//INPUTS
//author: @Anisat Yunusa

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.form-control {
  display: block;
  width: 100%;
  color: $black;
  font-family: "Inter", sans-serif;
  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 0.2rem rgba($primary, 10%);
  }
}

.form-group {
  width: 100%;
  position: relative;
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }

  #eye-icon {
    width: 16px;
    height: 15px;
  }

  #eye-icon-closed {
    width: 16px;
    height: 13px;
  }

  #toggle-icon,
  .input-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: inherit;
    animation: fadeUp 0.3s linear forwards;
  }
  #toggle-icon {
    svg {
      cursor: pointer;
    }
  }

  @keyframes fadeUp {
    0% {
      transform: translateY(-8px);
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 1rem;
    -webkit-appearance: none;
    padding: 1.2rem 1.25rem;
    border: 1px solid rgba($color: #d3d3d3, $alpha: 0.4);
    border-radius: 8px;
    background: inherit;
    &:focus {
      outline: 0;
    }
    &:disabled {
      background: #f5f5f5;
      cursor: not-allowed;
    }
    @media screen and (max-width: 575.98px) {
      font-size: 0.875rem;
    }
  }

  select {
    // background: url(../../assets/svgs/Down-arrow.svg) right 1.25rem center
    // 	no-repeat;

    .select--placeholder {
      color: rgba($color: $black, $alpha: 0.6) !important;
    }
  }

  .form-control--sm {
    height: 40px;
    padding: 10px 20px;
    font-size: 14px;
  }
}

.error {
  color: red;
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-size: 0.75rem !important;
  &::first-letter {
    text-transform: uppercase;
  }
}

.helperText {
  position: relative;
  display: inline-block;
  color: $f-blue;
  font-weight: 500;
  margin-top: 0.75rem;
}

.floating-label {
  label {
    position: absolute;
    left: 1.25rem;
    color: rgba($color: #717171, $alpha: 0.6);
    font-size: 1rem;
    transform-origin: top left;
    text-transform: capitalize;
    transform: translate(0, 20px) scale(1); //move on the y-axis
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media screen and (max-width: 575.98px) {
      font-size: 0.875rem;
    }
  }

  .form-control {
    height: 60px;
    &--sm {
      height: 40px;
      padding: 10px 20px !important;
      font-size: 14px;
    }
  }

  /** active label */
  &.active {
    label {
      color: $gray;
      //move the x coordinate and reduce size
      transform: translate(0, 10px) scale(0.75);
    }
  }

  /*dates only to prevent visual distortion*/
  &.date-label-container {
    label {
      color: $gray;
      //move the x coordinate and reduce size
      transform: translate(0, 10px) scale(0.75);
    }
    input {
      padding: 25px 20px 7px 20px;
    }
  }
  &.active {
    input,
    select,
    textarea {
      padding: 25px 20px 7px 20px;
    }
  }
}

.form-control.textArea {
  height: 100px;
}

input[type="date"],
input[type="time"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 9999;
}

.inputgroup {
  display: flex;
  width: 100%;
  &:last-child {
    .form-group {
      margin-bottom: 0;
    }
  }
  .form-group,
  .btn {
    flex: 1;
    &:last-child {
      margin-left: 1.5rem;
      @media #{$mediumdown} {
        margin-left: 1rem;
      }
    }
  }
  @media #{$smalldown} {
    flex-direction: column;

    &:last-child {
      .form-group,
      .btn {
        margin-bottom: 1.5rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .form-group,
    .btn,
    .card-check {
      &:last-child {
        margin-left: 0;
        margin-bottom: 1.5rem;
      }
    }

    .btn {
      flex: unset;
    }
  }
}

.inputDrop--container {
  position: relative;
  margin-bottom: 1.5rem;
  .dropDown {
    width: 100%;
    height: fit-content;
    background-color: #ffffff;
    border: 1px solid #f2f6f5;
    position: absolute;
    z-index: 1;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    li {
      color: $gray;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      padding: 0.5rem 1.25rem;

      cursor: pointer;
      translate: 0.4s;
      &:hover {
        color: #fff;
        background-color: #717171;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.inputDrop {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  background-color: none;
  border: 1px solid #d3d3d399;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border-color: $primary;
    box-shadow: 0 0 0 0.2rem rgba($primary, 10%);
  }
  padding: 1rem 1.25rem;

  cursor: pointer;
  &__text {
    color: rgba($color: #717171, $alpha: 0.6);
    font-family: inherit;
    font-size: 1rem;
  }
  .selectedInput {
    color: $black;
  }
}

// .toggle-switch {
//   margin: 0px;
//   padding: 0px;

//   position: relative;
//   width: 40px;
//   height: 20px;
//   display: inline-block;
//   vertical-align: middle;
//   user-select: none;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   text-align: left;
//   &-checkbox {
//     display: none;
//   }
//   &-label {
//     display: block;
//     overflow: hidden;
//     cursor: pointer;
//     border: 0 solid #f2f4f7;
//     border-radius: 20px;
//     margin: 0;
//   }
//   &-inner {
//     display: block;
//     width: 200%;
//     margin-left: -100%;
//     transition: margin 0.2s ease-in 0s;
//     &:before,
//     &:after {
//       display: block;
//       float: left;
//       width: 50%;
//       height: 20px;
//       padding: 0;
//       line-height: 20px;
//       font-size: 14px;
//       color: white;
//       font-weight: bold;
//       box-sizing: border-box;
//     }
//     &:before {
//       content: "";
//       text-transform: uppercase;
//       padding-left: 10px;
//       background-color: $f-blue;
//       color: #fff;
//     }
//   }
//   &-disabled {
//     background-color: #ddd;
//     cursor: not-allowed;
//     &:before {
//       background-color: #ddd;
//       cursor: not-allowed;
//     }
//   }
//   &-inner:after {
//     content: "";
//     text-transform: uppercase;
//     padding-right: 10px;
//     background-color: #f2f4f7;
//     color: #fff;
//     text-align: right;
//   }
//   &-switch {
//     display: block;
//     width: 16px;
//     height: 16px;
//     margin: 2px;
//     background: #fff;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     right: 20px;
//     border: 0 solid #f2f4f7;
//     border-radius: 20px;
//     transition: all 0.3s ease-in 0s;
//   }
//   &-checkbox:checked + &-label {
//     .toggle-switch-inner {
//       margin-left: 0;
//     }
//     .toggle-switch-switch {
//       right: 0px;
//     }
//   }
// }

.toggle--container {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #5c6368;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  @media #{ $smalldown } {
    font-size: 14px;
    line-height: 16.94px;
    text-align: left;
  }
}
.toggle-switch {
  position: relative;
  width: 40px;
  height: 20px;
  display: inline-block;
  text-align: left;
}

.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #f2f4f7;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: $f-blue;
  color: #fff;
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #f2f4f7;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 16px;
  height: 16px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border: 0 solid #f2f4f7;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}
.checkbox:disabled + .label .inner {
  cursor: not-allowed;
}
.checkbox:disabled + .label .switch {
  cursor: not-allowed;
}

.selectbox {
  position: relative;
  width: 100%;
  border: 1px solid #d3d3d399;
  border-radius: 8px;
  margin-bottom: 24px;
  cursor: pointer;
  &__field {
    padding: 20px 24px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > p {
      color: rgba(92, 99, 104, 0.6);
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: left;
      span {
        &:last-child {
          display: none;
        }
      }
    }
    > .active {
      color: #000b14;
    }
  }
  &__dropdown {
    position: absolute;
    top: 60px;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    border: 1px solid #f2f6f5;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .select {
      padding: 8px 20px;

      > li {
        padding-bottom: 12px;
        display: flex;
        gap: 8px;
        align-items: center;

        > input {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
        > label {
          color: #5c6368;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }
      }
    }
  }
}

.countryCodes {
  .selectbox__field {
    gap: 10px;
  }
  .selectbox__dropdown {
    height: 200px;
    overflow: scroll;
    z-index: 9999;
  }
  .country-list {
    display: flex;
    > img {
      width: 22px;
      height: 18px;
    }
    .codes {
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      color: #5c6368;
      margin-top: 0rem;
    }
  }
}
.selected__country {
  display: flex;
  align-items: center;
  gap: 4px;
  > img {
    width: 22px;
    height: 18px;
  }
  .codes {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    color: #5c6368;
  }
}

.phoneInput {
  margin-bottom: 24px;
}
