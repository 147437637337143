.plan {
  padding: 40px;
  @media #{ $mediumdown } {
    padding: 20px;
  }
  .plan__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    padding: 20px 30px;
    align-items: center;
    border: 1px solid #d3d3d399;
    border-radius: 12px;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: darken(#f5f5f5, 3%);
    }
    .plan__name {
      display: flex;
      gap: 10px;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #344054;
      text-transform: capitalize;
      max-width: 80%;
      @media #{ $mediumdown } {
        font-size: 16px;
      }
    }
    .plan__details {
      color: #005397;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      text-decoration: underline;
      cursor: pointer;
      > p {
        @media #{ $mediumdown } {
          display: none;
        }
      }
      .sm--details {
        @media #{ $mediumup } {
          display: none;
        }
      }
    }
  }
  .planPricing {
    margin: 0;
    .planPicing--header__info {
      > p {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #5c6368;
      }
    }
    .add--input {
      > p {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        color: #000b14;
      }
    }
  }
  .price__duration {
    margin-top: 20px;
    width: 100%;
    > p {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #5c6368;
    }
    &--lists {
      // width: 100%;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 20px;
      justify-content: space-between;

      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      @media #{ $smalldown } {
        flex-wrap: nowrap;
        flex-direction: column;
      }

      .list {
        display: flex;
        border: 1px solid #F5F5F5;
        height: 44px;
        border-radius: 8px;
        flex: 1 1 calc(33.33% - 20px);

        > input {
          outline: none;
          border: none;
          background-color: transparent;
          color: #5c6368;
          // padding: 18px 30px;
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          text-align: center;
          width: 50%;
        }
        > input:disabled {
          background-color: #f5f5f5;
        }
        > span {
          background-color: #d3d3d399;
          width: 50%;
          text-align: center;
          padding: 18px 0px;
          color: #5c6368;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          overflow: hidden;
          text-transform: capitalize;
          // height:  ;
        }
        > .active {
          background-color: #005397;
          color: #fff;
          overflow: hidden;
        }
      }
      .priceplan {
        display: flex;
        flex: 1 1 calc(33.33% - 20px);
        max-width: 298px;
        min-width: 230px;
        align-items: center;
        justify-content: space-between;
        padding-right: 60px;
        padding: 12px;

        > .price {
          text-transform: capitalize;
          font-size: 16px;
          font-weight: 500;
          line-height: 19.36px;
          width: 128px;
          color: #000b14;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          width: 128px;
          text-align: left;
          line-height: 19.36px;
          color: #5c6368;
        }
        @media #{ $mediumdown } {
          padding: 8px 12px;
        }
      }
    }
  }
  .penIcon {
    margin-right: 10px;
  }
}
.deltePlans {
  span {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  h4 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    text-align: center;
  }
  p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: 0.004em;
    text-align: center;
  }
}

.btn--container {
  display: flex;
  gap: 20px;

  &--small {
    flex-wrap: wrap;
    margin-top: 16px;

  }

  @media #{ $xs-md-down } {
    flex-direction: column-reverse;
    gap: 0px;
  }
}

