.onboarding {
  padding: 40px;
  @media #{ $smalldown } {
    padding: 20px;
  }
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    @media #{ $smalldown } {
      padding: 20px;
      background-color: #f5f5f5;
      border-radius: 12px;
    }

    &__items {
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        @media #{ $smalldown } {
          gap: 8px;
        }
        > span {
          margin: 0 auto;
          background-color: #f2f4f7;
          @media #{ $smalldown } {
            background-color: #fff;
          }

          padding: 10px;
          border-radius: 100px;
          > svg {
            fill: $gray;
            color: $gray;
            stroke: $gray;
          }
        }
        > p {
          margin-top: 8px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: -0.02em;
          text-align: center;
          color: $gray-2;
          @media #{ $smalldown } {
            display: none;
            margin-top: 0;
            max-width: 90px;
            text-align: left;
            font-size: 12px;
          }
        }
      }
    }
    .current {
      .item {
        @media #{ $smalldown } {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        > span {
          background-color: #fff;
          border: 1px solid $f-blue;
          > svg {
            fill: $f-blue;
            color: $f-blue;
            stroke: $f-blue;
          }
        }
        > p {
          color: $f-blue;
          @media #{ $smalldown } {
            display: block;
          }
        }
      }
    }
    .active {
      .item {
        > span {
          background-color: #005397;
          > svg {
            fill: #fff;
            color: #fff;
            stroke: #fff;
          }
        }
        > p {
          color: $f-blue;
        }
      }
    }
  }
}
.tab {
  flex: 1;
  height: 1px;
  background-color: #f2f4f7;
  margin: 0 10px;
  @media #{ $smalldown } {
    display: none !important;
  }
}
.activeTab {
  background-color: #005397;
}

.selectBranch {
  > h1 {
    text-align: center;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 33.89px;
    text-align: center;
    margin-top: 70px;
    @media #{ $smalldown } {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
    }
  }
  > p {
    text-align: center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 0.01em;
    margin-top: 8px;
    margin-bottom: 50px;
    @media #{ $smalldown } {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }
  }
  &--body {
    display: flex;
    gap: 50px;
    @media #{ $smalldown } {
      gap: 20px;
      flex-direction: column;
    }
  }
  &--option {
    input:checked + .card-check__value {
      border: none !important;
      background-color: $f-blue;

      > span {
        background-color: #fff;
        border: none !important;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          display: block;
        }
      }
      .card-check__value-title,
      .card-check__value-subtext {
        color: #fff !important;
      }
    }

    > .card-check__value {
      display: flex;

      flex-direction: column;
      padding: 30px;
      background-color: #fff;
      border: none;
      transition: all 0.5s;
      box-shadow: 0px 4px 6px 0px #0000001f;
      @media #{ $smalldown } {
        border: 1px solid #d3d3d399;
      }
      &:hover {
        background-color: #f5fbff;
        border: 1px dashed $f-blue;
      }

      > span {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 1px solid #d3d3d399;
        display: block;
        align-self: flex-end;
        svg {
          display: none;
        }
      }
    }
    .card-check__value-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      > svg {
        background-color: #f5fbff;
        border-radius: 100px;
      }
    }
    .card-check__value-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      color: #344054;
      margin-top: 20px;
    }
    .card-check__value-subtext {
      color: #667085;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      margin-top: 12px;
    }
  }
}
.businessInfo {
  margin-top: 40px;
  &--header {
    &__deets {
      background-color: $f-blue;
      border: 1px solid $stroke-alt;
      padding: 20px 30px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      h1 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 29.05px;
        text-align: left;
        @media #{ $smalldown } {
          font-size: 14px;
          font-weight: 600;
          line-height: 16.94px;
          text-align: left;
        }
      }
      p {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        letter-spacing: 0.01em;
        text-align: left;
        margin-top: 12px;
        @media #{ $smalldown } {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.52px;
          letter-spacing: 0.01em;
          text-align: left;
        }
      }
    }
    &__info {
      background-color: #fff;
      border: 1px solid #e4e7ec;
      display: flex;
      gap: 1rem;
      padding: 25px 30px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      @media #{ $smalldown } {
        padding: 16px 16px;
        align-items: center;
      }
      .img__container {
        width: 80px;
        height: 80px;
        @media #{ $smalldown } {
          width: 50px;
          height: 50px;
        }
        > img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
      .upload {
        color: #475467;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
      }
      .pic-format {
        margin-top: 4px;
        color: #667085;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        @media #{ $smalldown } {
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 14.52px;
          text-align: left;
        }
      }
      .upload__btn {
        color: $f-blue;
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        margin-top: 12px;
        text-decoration: underline;
        cursor: pointer;
        position: relative;
        @media #{ $smalldown } {
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          text-align: left;
        }

        > input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          box-sizing: border-box;
        }
      }
    }
  }
  &--body {
    position: relative;
    margin-top: 10px;
  }
}

.planPricing {
  margin-top: 40px;
  &--header__info {
    background-color: #fff;
    border: 1px solid #e4e7ec;
    padding: 25px 30px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    > .accordion {
      margin-bottom: 20px;
    }
  }
  .plan--deets {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #d3d3d399;
    padding: 12px 24px;
    margin-top: 20px;
    > label {
      display: flex;
      gap: 8px;
      padding: 8px 0px;
      color: #5c6368;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;

      > input {
        width: 16px;
        height: 16px;
        outline: none;
        border: 1px solid #d0d5dd;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .showOnFlance {
      padding: 9.5px 0px;
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &__btn {
      > button {
        width: 218px;
        border: 1px solid #005397;
        background-color: #ffffff;
        height: 58px;
        border-radius: 8px;
        color: #005397;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
        letter-spacing: 0.02em;
        text-align: center;
        margin-top: 20px;
        text-transform: uppercase;
      }
    }
  }
  .commission {
    display: flex;
    width: 100%;
    gap: 8px;
    background-color: #f5fbff;
    padding: 16px;
    border-radius: 8px;
    margin-top: 12px;
    @media #{ $smalldown } {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.94px;
      text-align: left;
    }
    > svg {
      color: #005397;
    }
    > p {
      padding: 0px;
      font-weight: 500;
      color: $f-blue;
    }
  }
}

.addClients {
  &--header {
    &__deets {
      background-color: transparent;
      border: 1px solid $stroke-alt;
      padding: 20px 30px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      h1 {
        color: #011627;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 29.05px;
        text-align: left;
        @media #{ $smalldown } {
          font-size: 14px;
          font-weight: 600;
          line-height: 16.94px;
          text-align: left;
        }
      }
      p {
        color: #5c6368;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        letter-spacing: 0.01em;
        text-align: left;
        margin-top: 12px;
        @media #{ $smalldown } {
          font-size: 12px;
          font-weight: 400;
          line-height: 14.52px;
        }
      }
    }
    &__info {
      background-color: #fff;
      border: 1px solid $stroke-alt;
      border-top: none;
      gap: 1rem;
      padding: 25px 30px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      @media #{ $smalldown} {
        border: none;
        padding: 0;
        margin-top: 20px;
      }
      .add--image {
        display: inline-block;
        position: relative;
        height: 224px;
        border: 1px dashed #005397;
        padding: 16px 18.5px;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 8px;
        justify-content: center;
        background-color: #f5fbff;
        .image--text {
          > p {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: $f-blue;
            text-align: center;
            margin-top: 12px;
            margin-bottom: 6px;
            padding: 0;
            > span {
              text-decoration: underline;
            }
          }
        }
        > span {
          color: #475467;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: center;
        }

        .file-input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
          box-sizing: border-box;
          &::-webkit-file-picker-indicator {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            cursor: pointer;
          }
        }
      }
      .csv--type {
        font-size: 20px;
        font-weight: 500;
        line-height: 24.2px;
        text-align: left;
        color: #5c6368;
        @media #{ $smalldown } {
          font-size: 10px;
          font-weight: 500;
          line-height: 12.1px;
          text-align: left;
        }
      }
      .template {
        display: flex;
        width: 100%;
        justify-content: space-between;
        border: 1px solid #d3d3d399;
        background-color: #f5f5f5;
        padding: 20px 30px;
        border-radius: 12px;
        margin-top: 30px;
        &--text {
          > h3 {
            color: #344054;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            display: flex;
            gap: 12px;
            align-items: center;
            @media #{ $smalldown } {
              font-size: 14px;

              line-height: 16.94px;
              text-align: left;
            }
          }
          > p {
            color: #5c6368;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.94px;
            text-align: left;
            margin-top: 12px;
            @media #{ $smalldown } {
              font-size: 12px;
              font-weight: 400;
              line-height: 14.52px;
              text-align: left;
            }
          }
        }
        &--btn {
          > a {
            button {
              display: flex;
              gap: 10px;
              outline: none;
              align-items: center;
              border: 1px solid #005397;
              background-color: white;
              padding: 18px 24px;
              border-radius: 8px;
              color: $f-blue;
              font-size: 1rem;
              font-weight: 600;
              line-height: 19.36px;
              letter-spacing: 0.02em;
              text-align: center;
              transition: all 0.5s;
              &:hover,
              &:focus {
                background-color: darken(#ffffff, 3%);
              }
              span {
                @media #{ $mediumdown } {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.clients__phone {
  .form-group {
    margin-bottom: 0px;
  }
  > small {
    color: #71717199;
  }
  margin-bottom: 20px;
}
.input-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  @media #{ $mediumdown } {
    flex-direction: column;
  }
  .address--deets {
    width: 33%;
    @media #{ $mediumdown } {
      width: 100%;
    }
    > p {
      color: #344054;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 6px;
    }
  }
}
.input-time {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  @media #{ $mediumdown } {
    flex-direction: column;
  }

  .address--deets {
    width: 50%;
    @media #{ $mediumdown } {
      width: 100%;
    }
    > p {
      color: #344054;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      margin-bottom: 6px;
    }
  }
}

.accordion {
  // background: $primary-light-1;
  overflow: hidden;
  width: 100%;
  &__item {
    position: relative;
    padding-bottom: 30px;
    border: 1px solid #d3d3d399;
    overflow: hidden;
    border-radius: 12px;
    @media #{ $smalldown } {
      padding-bottom: 0px;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
      @media #{ $smalldown } {
        margin-bottom: 16px;
      }
    }
  }

  &__heading {
    padding: 20px 30px;
    background: #f5f5f5;
    .main--text {
      color: #344054;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      @media #{ $smalldown } {
        font-size: 16px;
        line-height: 19.36px;
      }
    }
    .sub--text {
      margin-top: 12px;
      color: #5c6368;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      @media #{ $smalldown } {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
      }
    }
  }

  &__button {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.004em;
    cursor: pointer;
    @media #{$mediumup} {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 29px;
      letter-spacing: 0.02em;
      padding-right: 2.5rem;
    }

    &::after {
      content: "";
      position: absolute;
      right: 30px;
      top: 30px;
      width: 15px;
      height: 15px;
      background: url(../../assets/svgs/downcaret.svg) no-repeat center;
      background-size: 100%;
      font-size: 0.75rem;
      // color: #0e0842;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.5s;
    }
  }

  &__button[aria-expanded="true"]::after {
    transform: rotate(180deg);
    background: url(../../assets/svgs/downcaret.svg) no-repeat center;
    top: 32px;
  }

  &__panel {
    width: 100%;
    padding: 25px 30px;
    @media #{ $smalldown } {
      padding: 16px 16px;
    }

    p {
      padding-bottom: 6px;
      color: #344054;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
    .input-group {
      width: 100%;
      display: flex;
      gap: 20px;
      padding-bottom: 12px;
      @media #{ $smalldown } {
        flex-direction: column;
      }
      .address--deets {
        width: 100%;
      }
    }
    > label {
      display: block;
      margin-bottom: 14px;
      color: #344054;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      display: flex;
      gap: 8px;
      input {
        width: 16px;
        height: 16px;
      }
    }
    .add--items {
      background-color: #f5f5f5;
      padding: 20px;
      margin-top: 20px;
      border-radius: 12px;
      &__input {
        width: 100%;
        display: flex;
        gap: 12px;
        @media #{ $smalldown } {
          flex-direction: column;
        }

        .add--input {
          width: 100%;
          background-color: #fff;
          border-radius: 12px;
        }

        > button {
          width: 84px;
          border: 1px solid #005397;
          background-color: #ffffff;
          height: 58px;
          border-radius: 8px;
          color: #005397;
          font-size: 16px;
          font-weight: 600;
          line-height: 19.36px;
          letter-spacing: 0.02em;
          text-align: center;
          @media #{ $smalldown } {
            width: 100%;
          }
        }
      }
    }
    .website {
      margin-top: 20px;
    }
    .time--bar {
      display: inline-block;
      position: relative;
      width: 155px;
      height: 56px;
      border: 1px solid #d3d3d399;
      padding: 16px 18.5px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      @media #{ $xs-md-down } {
        width: 130px;
      }

      .time-toggle-button {
        position: absolute;
        left: -24px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: url(../../assets/svgs/downcaret.svg) no-repeat right;
      }
      > p {
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 11, 20, 0.6);
        padding: 0;
        @media #{ $mediumdown } {
          font-size: 14px;
        }
      }
      .timepicker-input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        box-sizing: border-box;
        &::-webkit-calendar-picker-indicator {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
      }
    }
    .disabled {
      background-color: #d3d3d399;

      > .timepicker-input {
        cursor: not-allowed !important;
      }
    }
    .opening__hours {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0px;
      border-bottom: 1px solid #d3d3d399;
      @media #{ $mediumdown } {
        flex-direction: column;
        align-items: flex-start;
      }

      .time__switch {
        display: flex;
        justify-content: space-between;
        width: 50%;
        @media #{ $mediumdown } {
          width: 100%;
        }
        > p {
          padding: 0px;
          font-size: 1rem;
          font-weight: 600;
          line-height: 19.36px;
          text-align: left;
          width: 15%;
        }
      }
      .time--section {
        display: flex;
        align-items: center;
        gap: 20px;
        @media #{ $mediumdown } {
          margin-top: 15px;
        }
      }
      .add--more {
        color: $f-blue;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        > span {
          margin-right: 20px;
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
    .add--images {
      // display: flex;
      // width: 100%;
      // gap: 20px;
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
      margin-top: 1.5rem;

      @media #{ $largeup } {
        grid-template-columns: repeat(2, 1fr);
      }
      .selected--img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
      &__container {
        // width: 50%;
        .radio__container {
          display: flex;
          align-items: center;
          padding-top: 20px;
          gap: 16px;
          > input {
            height: 16px;
            width: 16px;
          }
          > label {
            color: $black;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
          }
        }
      }
    }
    .add--image {
      display: inline-block;
      position: relative;
      height: 345px;
      border: 1px dashed #005397;
      padding: 16px 18.5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      border-radius: 8px;
      justify-content: center;
      background-color: #f5fbff;
      @media #{ $smalldown } {
        height: 176px;
      }
      .image--text {
        > p {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: $f-blue;
          text-align: center;
          margin-top: 12px;
          margin-bottom: 6px;
          padding: 0;
          > span {
            text-decoration: underline;
          }
        }
      }
      > span {
        color: #475467;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.94px;
        text-align: center;
      }

      .file-input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        box-sizing: border-box;
        &::-webkit-file-picker-indicator {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
          cursor: pointer;
        }
      }
    }
    > button {
      width: 218px;
      border: 1px solid #005397;
      background-color: #ffffff;
      height: 58px;
      border-radius: 8px;
      color: #005397;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
      letter-spacing: 0.02em;
      text-align: center;
      margin-top: 20px;
    }
  }
  .price__duration {
    margin-top: 20px;
    width: 100%;
    &--lists {
      // width: 100%;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 20px;
      justify-content: space-between;

      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      @media #{ $mediumdown } {
        flex-wrap: nowrap;
        flex-direction: column;
      }

      .list {
        display: flex;
        border: 1px solid #d3d3d399;
        height: 54px;
        border-radius: 8px;
        flex: 1 1 calc(33.33% - 20px);

        > input {
          outline: none;
          border: none;
          background-color: transparent;
          color: #5c6368;
          // padding: 18px 30px;
          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          text-align: center;
          width: 50%;
        }
        > span {
          background-color: #d3d3d399;
          width: 50%;
          text-align: center;
          padding: 18px 0px;
          color: #5c6368;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          overflow: hidden;
          text-transform: capitalize;
          // height:  ;
        }
        > .active {
          background-color: #005397;
          color: #fff;
          overflow: hidden;
        }
      }
    }
  }
}

.addNew__branch {
  width: 100%;
  border: 1px solid #005397;
  background-color: #ffffff;
  height: 58px;
  border-radius: 8px;
  color: #005397;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
  span {
    margin-right: 10px;
    font-size: 24px;
  }
}

.up-success {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #344054;
  margin-top: 12px;
  margin-bottom: 20px;
  @media #{ $smalldown } {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}
.changeFile {
  display: flex;
  justify-content: space-between;
  width: 95%;
  border-radius: 12px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #d3d3d399;
  padding: 16px 20px;
  @media #{ $largedown } {
    background-color: transparent;
    border: none;
    flex-direction: column;
    align-items: center;
  }
  .file--deets {
    display: flex;
    align-items: center;
    gap: 16px;
    &__name {
      font-size: 14px;
      font-weight: 700;
      line-height: 16.94px;
      text-align: left;
      color: #5c6368;
      span {
        font-weight: 400;
        @media #{ $smalldown } {
          display: none;
        }
      }
    }
    &__size {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.94px;
      text-align: left;
      color: #5c6368;
      list-style: #5c6368;
    }
  }
  > .action--btn {
    display: flex;
    gap: 16px;
    @media #{ $smalldown } {
      margin-top: 16px;
    }
    > .upload__btn {
      color: $f-blue;
      font-size: 1rem;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      margin-top: 0px;
      text-decoration: underline;
      cursor: pointer;
      position: relative;
      @media #{ $smalldown } {
        font-size: 12px;
        font-weight: 600;
        line-height: 16.94px;
        text-align: left;
      }
      > input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        box-sizing: border-box;
      }
    }
    > a {
      color: $f-blue;
      font-size: 1rem;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      margin-top: 0px;
      text-decoration: underline;
      cursor: pointer;
      @media #{ $smalldown } {
        font-size: 12px;
        font-weight: 600;
        line-height: 16.94px;
        text-align: left;
      }
    }
  }
}
