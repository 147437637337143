*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
}

html {
  color: $black;
}

body {
  color: $black;
  font-size: 16px;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
  scroll-behavior: smooth;
}

a {
  outline: none;
  text-decoration: none;
}

ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
// p {
// 	text-transform: capitalize;
// }

svg {
  vertical-align: middle;
  fill: inherit;
}

button {
  outline: none;
  border: 0;
  font-family: "Inter", sans-serif;
  background-color: transparent;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  opacity: 0;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
  opacity: 0;
}

.tab {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

//Typography
@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: url(../../assets/fonts/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: url(../../assets/fonts/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: url(../../assets/fonts/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: url(../../assets/fonts/Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: url(../../assets/fonts/Inter-ExtraBold.ttf) format("truetype");
}

.avatar {
  &--round {
    border-radius: 50% !important;
  }
  &--round-squared {
    border-radius: 5px !important;
    margin-right: 15px;
  }
}

.prev-btn {
  display: flex;
  align-items: center;
  gap: 16px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  color: $gray;
  cursor: pointer;
  margin-bottom: 32px;
  text-transform: capitalize;
  transition: all 0.3s;
  // .back-icon {

  // 	color: #000;
  // }
  &:hover {
    color: $black !important;
    font-weight: 600 !important;
  }
}

.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
  // border-bottom: 1px dashed #000;
  /* little indicater to indicate it's hoverable */
  z-index: 10;
}
.tooltip:hover:before {
  display: block;
}
.tooltip:after {
  content: "";
  position: absolute;

  /* position tooltip correctly */
  left: 100%;
  margin-left: -5px;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* the arrow */
  border: 10px solid #000;
  border-color: transparent black transparent transparent;

  display: none;
}
.tooltip:hover:before,
.tooltip:hover:after {
  display: block;
}
.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 100%;
  margin-left: 15px; /* and add a small left margin */

  /* basic styles */
  width: 300px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  // text-align: center;

  display: none; /* hide by default */
}
.tooltip:before {
  /* other styles */

  /*  display:none; */

  opacity: 0;
  transition: 0.3s opacity;
}
.tooltip:hover:before {
  opacity: 1;
}
.spinner_loader {
  width: fit-content;
  margin: 100px auto;
}

.toaster__style {
  position: absolute;
  z-index: 1000;
}
.hidden {
  display: none;
}

.tip__container {
  background-color: #f8fcff;
  display: flex;
  gap: 1rem;
  padding: 1.5rem 1rem;
  border-radius: 8px;
  > p {
    color: #000b14;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    > b {
      color: #005397;
    }
  }
}
