table {
  width: 100%;
  overflow-x: scroll;
  // background-color: $primary-light-2;
  border-radius: 15px;
  border-collapse: collapse;
  @media #{ $mediumdown } {
    display: none;
  }

  // background-color: red;
  // padding: 0 32px;
  // padding-right: 20px;
  tr {
    height: 56px;
    border-radius: 15px;
    padding: 0 32px;
    .check {
      width: 16px;
      height: 16px;
      cursor: pointer;
      border: 1px solid #d3d3d399;
    }
    th {
      border: none;
      border-bottom: 1px solid #f5f5f5;
      // background: $primary-light-2;
      background: #f5f5f5;

      text-align: left;
      padding: 0 32px;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 15px;
      letter-spacing: 0.004em;
      text-transform: uppercase;
      color: $gray;
      border: none;
      &:first-child {
        border-top-left-radius: 15px;
      }
      &:last-child {
        border-top-right-radius: 15px;
      }

      // border-collapse: collapse;
    }
  }

  tr {
    &:hover {
      background-color: #f5f5f5 !important;
    }

    .delete-row {
      color: #e0063c;

      &:hover {
        text-decoration: underline;
        font-weight: 500;
      }
    }

    td {
      position: relative;
      padding: 8px 0px;
      padding-left: 32px;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 17px;
      color: rgba(0, 11, 20, 0.8);
      // background-color: #ffffff;
      border-bottom: 1px solid #f5f5f5;
      justify-content: left;
      text-transform: capitalize;
      transition: 0.3s;
      &:last-child {
        cursor: pointer;
      }
    }
    .plan-table {
      padding: 16px 0px;
      padding-left: 32px;
      border-top: 1px solid #f5f5f5;
      border-bottom: none;
      text-transform: capitalize;
    }
    // .duration {
    // }
    .status {
      padding: 8px 16px;
      border-radius: 100px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.75rem;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 6px;
      width: fit-content;
    }
    
    .employee-active {
      background: #ebf7ee;
      color: #3bad54;
    }
    .employee-inactive {
      background: #f5f5f5;
      color: $gray;
    }
    .pending {
      color: #f79f1a;
      background: #fef1dd;
    }
  }
}
.grey-bg {
  transition: 0.5s;
  &:hover {
    background-color: #f5f5f5 !important;
  }
}
.type {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #005397;
  text-transform: capitalize;
}

.smaller__table {
  display: none;
  @media #{ $mediumdown } {
    display: block;

    .list-container {
      border: 1px solid #d3d3d3;
      margin-bottom: 20px;
      padding: 16px 20px;
      border-radius: 12px;

      .table__head {
        position: relative;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      p {
        font-weight: 400;
        font-size: 0.87rem;
        line-height: 17px;
        color: rgba(0, 11, 20, 0.8);
        // opacity: 0.8;
        padding: 16px 0;
      }
    }
  }
}

.table--pagination {
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media #{ $mediumdown } {
    justify-content: center;
  }
  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 17px;
    letter-spacing: 0.004em;
    text-transform: uppercase;
    color: $gray;

    @media #{ $mediumdown } {
      display: none;
    }
  }
  .pages {
    display: flex;
    gap: 24px;
    align-items: center;

    .prev {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        letter-spacing: 0.004em;
        text-transform: uppercase;
        color: $gray;
      }
    }
    .next {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        letter-spacing: 0.004em;
        text-transform: uppercase;
        color: $black;
      }
    }
    .numbers {
      display: flex;
      gap: 4px;
      span {
        width: 24px;
        height: 24px;

        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 500;
        font-size: 0.875rem;
        line-height: 17px;

        letter-spacing: 0.004em;
        color: #3d0814;
        cursor: pointer;
        &:hover {
          background-color: #f5f5f5;
        }
      }
      .active {
        background: #ff8788;
        &:hover {
          background: lighten(#ff8788, 20%);
          background-color: lighten($color: #ff8788, $amount: 5);
          // background: lighten(#ff8788, 20%);
        }
      }
    }
  }
  .pagecontrols {
    display: flex;
    gap: 10px;
    align-items: center;
    .showmore--container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      p{
        text-wrap: nowrap;
      }
      .showOptions {
        position: absolute;
        bottom: 0px;
        z-index: 1px;

        .showOption--numbers {
          max-height: 300px;
          height: fit-content;
          width: 50px;
          overflow: scroll;
          border: 1px solid #d3d3d399;
          background-color: inherit;
          background-color: #f5f5f5;
          .numbers {
            cursor: pointer;
            padding: 2.5px 10px;
          }
        }
      }
    }
    .container {
      border: 1px solid #d3d3d399;
      border-radius: 10px;
      padding: 15px;
      background-color: #f5f5f5;
      display: flex;
      position: relative;
      margin-top: 0px;
      svg {
        margin-left: 5px;
        transform: rotate(270deg);
      }
    }
  }
  .container {
    border: 1px solid #d3d3d399;
    border-radius: 10px;
    padding: 15px;
    background-color: #f5f5f5;
    display: flex;
    position: relative;
    margin-top: 0px !important;
    svg {
      margin-left: 5px;
    }
  }
}

.table-container {
  margin-top: 24px;
  // border: 1px solid #f5f5f5;
  // border-radius: 15px;
  background-color: #fff;
  border: 1px solid $off-white;
  border-radius: 15px;
  @media #{ $mediumdown } {
    border: none;
  }
}

.small__dashboard--table {
  display: flex;
  justify-content: space-between;
  .smallTable__right {
    .image-title {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    p {
      padding: 0px;
      color: #5c6368;
    }
    h3 {
      color: $black;
      font-size: 12px;
      font-weight: 500;
      line-height: 14.52px;
      text-align: left;
    }
    .deets {
      font-size: 10px;
      font-weight: 400;
      line-height: 12.1px;
      text-align: left;
      padding-top: 6px;
    }
    .activity {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #005397;
    }
    > .date--deet {
      font-size: 10px;
      font-weight: 400;
      line-height: 12.1px;
      text-align: left;
      padding-top: 6px;
    }
    .user {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .smallTable__left {
    > p {
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      color: #000b14;
      padding: 0px;
      padding-bottom: 10px;
    }
    .status {
      padding: 8px 16px;
      border-radius: 100px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.65rem;
      text-transform: uppercase;
      svg {
        margin-bottom: 3px;
      }
    }

    .mailContainer {
      display: flex;
      justify-content: flex-end;
    }
    .employee-active {
      background: #ebf7ee;
      color: #3bad54;
    }
    .employee-inactive {
      background: #f5f5f5;
      color: $gray;
    }
    .pending {
      color: #f79f1a;
      background: #fef1dd;
    }
  }
}

.mail {
  cursor: pointer;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
  width: fit-content;
  display: block;
  position: relative;

  .dropdown-mail {
    position: absolute;
    top: 30px;
    right: 0px;
    .topnav__dropdown {
      padding: 16px;
      width: 253px;
      .infobox {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        gap: 10px;
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.005em;
          color: #000b14;
        }
      }
      .noContact {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        gap: 10px;
        p {
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0.005em;
          color: #000b14;
        }
      }
      .noContact--body {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #5c6368;
      }
    }
  }
}
.mail--disabled {
  cursor: not-allowed;
}
.status {
  padding: 8px 16px;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  text-wrap: nowrap;
}

.employee-active {
  background: #ebf7ee;
  color: #3bad54;
}
.employee-inactive {
  background: #f5f5f5;
  color: $gray;
}

.member-SA {
  color: #005397;
  background-color: rgba(0, 83, 151, 0.1);  
}
.member-A {
  color: #029AFF;
  background-color: rgba(2, 154, 255, 0.1);  
}
.member-M {
  color: #8500A2;
  background-color: rgba(184, 6, 224, 0.1);  
}
.member-O {
  color: #3BAD54;
  background-color: rgba(59, 173, 84, 0.1);  
}

.pending {
  color: #f79f1a;
  background: #fef1dd;
}

.flancer {
  > svg {
    fill: #ff8788;
    color: #ff8788;
  }
}
.non-flancer {
  > svg {
    fill: #5c6368;
    color: #5c6368;
  }
}
